<template>
  <div>
    <button @click="endMeetingWarning" class="btn btn-block btn-info">
      <i class="fa fa-times"></i> {{ $t('OnlineMeetingComponent.EndMeeting') }}
    </button>
    <br />
    <div v-if="meeting.isGuestCustomer">
      <iq-card>
        <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title" style="border-bottom: 0px solid !important;">
            <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-info nav-tabs-line-2x nav-tabs-line-right"
                role="tablist">
              <li v-if="isHost" class="nav-item">
                <a class="nav-link active"
                   data-toggle="tab"
                   href="#kt_portlet_base_demo_2_3_tab_content"
                   role="tab">
                  <i class="fa fa-user-check"
                     aria-hidden="true" /> &nbsp; {{ $t("Customer") }}
                </a>
              </li>
              <li v-else class="nav-item">
                <a class="nav-link active"
                   data-toggle="tab"
                   href="#kt_portlet_base_demo_2_4_tab_content"
                   role="tab">
                  <i class="fa fa-user-check"
                     aria-hidden="true" /> &nbsp; {{ $t("Doctor") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"
                   data-toggle="tab"
                   href="#kt_portlet_base_demo_2_2_tab_content"
                   role="tab">
                  <i class="fa fa-file"
                     aria-hidden="true" /> &nbsp; {{ $t("Shared") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="iq-card-body">
          <div class="tab-content">
            <div v-if="isHost" class="tab-pane active"
                 id="kt_portlet_base_demo_2_3_tab_content"
                 role="tabpanel">
              <meeting-details-component :meeting="meeting"
                                         :is-host="isHost" />
            </div>
            <div v-else class="tab-pane active"
                 id="kt_portlet_base_demo_2_4_tab_content"
                 role="tabpanel">
              <meeting-staff-details-component :meeting="meeting" />
            </div>
            <div class="tab-pane"
                 id="kt_portlet_base_demo_2_2_tab_content"
                 role="tabpanel">
              <meeting-multimedia-component :meeting="meeting" />
            </div>
          </div>
        </div>
      </iq-card>
    </div>
  </div>
</template>

<script>
  import MeetingDetailsComponent from './MeetingDetailsComponent.vue'
  import MeetingStaffDetailsComponent from './MeetingStaffDetailsComponent.vue'
  import MeetingMultimediaComponent from './MeetingMultimediaComponent.vue'

  export default {
    name: 'OnlineMeetingRight',
    props: {
      meeting: null,
      isHost: Boolean,
    },
    components: {
      MeetingStaffDetailsComponent,
      MeetingMultimediaComponent,
      MeetingDetailsComponent
    },
    methods: {
      endMeetingWarning() {
        let $this = this;
        let _html = `<hr/><div class='col-md-12 row' style='margin-top: 2%;'><div class='col-md-12'>${$this.$t("OnlineMeetingComponent.ConfirmEndMeeting")}</div></div><br>`;
        $this.$swal.fire({
          icon: 'warning',
          html: _html,
          width: '500px',
          buttonsStyling: false,
          confirmButtonText: $this.$t("Continue"),
          confirmButtonClass: "btn btn-warning btn-sm",
          showCancelButton: true,
          cancelButtonText: $this.$t("Cancel"),
          cancelButtonClass: "btn btn-secondary btn-sm",
          reverseButtons: true

        }).then(function (result) {
          if (result.value) {
            $this.$parent.endMeeting();
            return;
          }
          else if (result.dismiss === 'cancel') {
            return;
          }
        });
      },

    }
  }
</script>
