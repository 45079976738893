<template>
  <span>
    <template>
      <div class="row">
        <div class="col-lg-12"
             style="display:flex; justify-content:center;">
          <div class="col-md-6">
            <div style="margin-top:0px !important; justify-content:center;">
              <div style="display:flex; justify-content:center;">
                <img src="https://s3.stellamedi.com/stellamedi-cdn/images/avatar-03.png"
                     alt="image"
                     style="border-radius:10%; max-width:50%;">
              </div>
            </div>
            <div style="margin:0px !important;">
              <div>
                <div style="justify-content:center;"
                     v-if="meetingInfo.staffName">
                  <span style="text-decoration:underline;">
                    <span style=" font-weight: bold; ">
                      {{ meetingInfo.staffName }}
                    </span>
                  </span>
                </div>
                <div v-if="meetingInfo.serviceName">
                  <span style=" font-weight: bold;"> {{$t('Service')}}:</span>
                  <span>
                    {{ meetingInfo.serviceName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </span>
</template>

<script>
  import MeetingService from '../../../services/meeting.js'

  export default {
    name: 'OnlineMeetingStaffDetails',
    props: {
      meeting: null,
      isHost: Boolean
    },
    data: function () {
      return {
        meetingInfo: {},
        customerAppointments: []
      }
    },
    methods: {
      getOnlineMeetingDetails() {
        if (this.meeting.id) {
          MeetingService.getOnlineMeetingDetails(this.meeting.id)
            .then(response => {
              this.meetingInfo = response;
            });
        }
      },
    },
    mounted: function () {
      this.getOnlineMeetingDetails();
    }
  }
</script>
