<template>
  <span>
    <template v-if="isHost">
      <div class="row">
        <div class="col-lg-12"
             style="display:flex; justify-content:center;">
          <div class="col-md-6">
            <div style="margin-top:0px !important; justify-content:center;">
              <div style="display:flex; justify-content:center;">
                <img src="https://s3.stellamedi.com/stellamedi-cdn/images/avatar-03.png"
                     alt="image"
                     style="border-radius:10%; max-width:50%;">
              </div>
            </div>
            <div style="margin:0px !important;">
              <div>
                <div style="justify-content:center;"
                     v-if="customerInfo.customerName">
                  <a @click="createLink()"
                     style="text-decoration:underline; cursor:pointer; cursor:pointer;">
                    <span style=" font-weight: bold; ">
                      {{ customerInfo.customerName }}
                    </span>
                  </a>
                </div>
                <div v-if="customerInfo.phone">
                  <span style=" font-weight: bold;">{{$t('Phone')}}:</span>
                  <span>
                    {{ customerInfo.phone }}
                  </span>
                </div>
                <div v-if="customerInfo.dateOfBirth">
                  <span style=" font-weight: bold;">{{$t('DateOfBirth')}}:</span>
                  <span>
                    {{ customerInfo.dateOfBirth | dateFormat }}
                  </span>
                </div>
                <div v-if="customerInfo.age && customerInfo.age > 0 ">
                  <span style=" font-weight: bold;">{{$t('Age')}}:</span>
                  <span>
                    {{ customerInfo.age }}
                  </span>
                </div>
                <div v-if="customerInfo.lastVisitDate">
                  <span style=" font-weight: bold;">{{$t('LastAppointmentDate')}}:</span>
                  <span>
                    {{ customerInfo.lastVisitDate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-sm table-head-bg-brand">
            <thead class="thead-inverse"
                   style="background-color:transparent !important;">
              <tr>
                <th colspan="3"
                    style="padding-bottom:0px; color:#646c9a;">{{$t('OnlineMeetingComponent.LastFiveAppointment')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app,index) in customerAppointments" :key="index" :style="{ backgroundColor: app.id == meeting.appointmentID ? '#f7f7fa' : ''}">
                <td width="15%">{{ app.startDate }}</td>
                <td width="20%"
                    v-if="app.id == meeting.appointmentID"
                    style="text-align:center;">
                  <button class="btn btn-sm btn-success"
                          disabled="disabled"
                          style="padding: 0.1rem 1rem;">
                    {{$t('Now')}}
                  </button>
                </td>
                <td width="20%"
                    v-else
                    style="text-align:center;">
                  {{ app.time }}
                </td>
                <td width="65%">{{ app.services }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-else>
      <h5>{{$t('OnlineMeetingComponent.DontShowHostInformations')}}</h5>
    </template>
  </span>
</template>

<script>
  import MeetingService from '../../../services/meeting.js'

  export default {
    name: 'OnlineMeetingDetails',
    props: {
      meeting: null,
      isHost: Boolean
    },
    data: function () {
      return {
        customerInfo: {},
        customerAppointments: []
      }
    },
    methods: {
      getCustomerDetails() {
        if (this.guestId) {
          MeetingService.getCustomerDetails(this.meeting.guestID)
            .then(response => {
              this.customerInfo = response;
            });
        }
      },
      createLink() {
        if (this.guestId) {
          let route = this.$router.resolve({ name: 'customerSummary', params: { customerId: this.meeting.guestID, elementId: 'aDetails' } })
          window.open(route.href, '_blank');
        }
      },
      getAppointments() {
        if (this.guestId) {
          MeetingService.getAppointments(this.meeting.guestID)
            .then(response => {
              this.customerAppointments = response;
            });
        }
      }
    },
    computed: {
      guestId: function () {
        return this.meeting.guestID != null && this.meeting.guestID.length > 0;
      }
    },
    mounted: function () {
      this.getCustomerDetails();
      this.getAppointments();
    }
  }
</script>
